span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
input,
textarea,
div,
button {
  color: white;
  font-family: 'Nunito Sans', sans-serif;
}

.MuiFormLabel-root,
.MuiInputBase-input {
  color: white;
  font-family: 'Nunito Sans', sans-serif;
}

.Mui-focused {
  color: rgb(118, 226, 224) !important;
}

.MuiInput-underline:after {
  border-color: rgb(118, 226, 224) !important;
}

h1 {
  line-height: 1;
  font-size: 30px;
  font-weight: 600;
}

h2 {
  color: #c1c1c1;
  font-size: 20px;
}

button {
  border-width: 0;
  outline: none;
}

a,
button {
  text-decoration: none;
}

input:focus,
select:focus,
p:focus,
textarea:focus {
  outline: none !important;
  border: 1px solid rgb(118, 226, 224);
  border-width: 1px !important;
}

input[type='checkbox'] {
  width: 20px !important;
  min-width: 20px !important;
  margin-right: 10px;
}
input[type='radio'] {
  width: 20px !important;
  min-width: 20px !important;
  margin-right: 10px;
}
input[type='radio']:focus,
input[type='checkbox']:focus {
  border: none;
}
input[type='radio'],
input[type='checkbox'] {
  appearance: none;
  position: relative;
}

input[type='radio']::after,
input[type='checkbox']::after {
  display: block;
  text-align: center;
  color: #009999;
  content: 'check_box_outline_blank';
  direction: ltr;
  font-family: 'Material Icons';
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  position: absolute;
  top: 2px;
  cursor: pointer;
}
input[type='radio']::after {
  content: 'radio_button_unchecked';
}
input[type='checkbox']:checked::after {
  content: 'check_box';
}
input[type='radio']:checked::after {
  content: 'radio_button_checked';
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.modal-open {
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 13px;
}

::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 0px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 0px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
